import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@elenasy/common-ui';

export function AuthGuard() {
  return () => {
    const router = inject(Router);
    const storageService = inject(StorageService);

    const token = storageService.studentToken;

    if (token && token.length) {
      // router.navigate([storageService.url]);
      return true;
    }

    router.navigate(['/auth']);
    return false;
  };
}
